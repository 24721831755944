<div class="radio-control">
    @for (option of options(); track option) {
    <label class="radio-control__option" kui-action [ngClass]="{'disabled' : option.disabled}">
        <input type="radio" name="video-types"
            [value]="option.value"
            [ngModel]="type"
            [disabled]="option.disabled"
            (ngModelChange)="handleModelChange($event)" />
        <span>{{option.name}}</span>
        @if(isLoading()) {
            <kui-loader size="small"></kui-loader>
        }
    </label>
    }
    
</div>