import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, model, output, signal, WritableSignal, type OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { KuiActionModule, KuiIconModule } from 'app/key-ui';
import { KuiLoaderModule } from 'app/key-ui/loader/loader.module';
import { AssetStateService } from 'app/services';
import { SelectionItem } from 'app/shared/components/selection/selection.model';
import { filter, finalize, from, map, Subject, takeUntil, tap } from 'rxjs';
import { VideoType } from '../request.component';

@Component({
    selector: 'key-video-type',
    standalone: true,
    imports: [
        CommonModule,
        KuiActionModule,
        KuiIconModule,
        FormsModule,
        KuiLoaderModule
    ],
    templateUrl: './video-type.component.html',
    styleUrl: './video-type.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoTypeComponent implements OnInit {

    private destroyed: Subject<void> = new Subject();

    asset = input.required<SelectionItem>();
    isLoading = signal(false);

    isActive: WritableSignal<boolean> = signal(false);
    options = computed<{ name: string; value: VideoType; disabled: boolean }[]>(() => [
        {
            name: this.i18n.instant('MEDIA.VIDEO_REQUEST.MODAL.VIDEO'),
            value: VideoType.CLIP,
            disabled: this.isLoading(), // disable whilst loading, as we recalculate the options array once we've finished loading
        },
        {
            name: this.i18n.instant('MEDIA.VIDEO_REQUEST.MODAL.LIVESTREAM'),
            value: VideoType.STREAM,
            disabled: !this.isActive(), // livestreaming should be disabled unless it's during a trip
        }
    ]);

    type = model<VideoType>();
    videoTypeSelected = output<VideoType>();

    constructor(
        public i18n: TranslateService,
        private assetStateService: AssetStateService
    ) { }

    ngOnInit(): void {
        from(this.assetStateService.getAsset(this.asset().id))
            .pipe(
                takeUntil(this.destroyed),
                tap(_ => this.isLoading.set(true)),
                map(asset => asset?.telemetry?.active),  // is this asset currently active?
                filter(active => active !== undefined),
                finalize(() => this.isLoading.set(false))
            )
            .subscribe(active => {
                this.isActive.set(active);
            });
    }

    ngOnDestroy(): void {
        this.destroyed.next();
    }

    isValid(): boolean {
        return this.type() !== undefined;
    }

    handleModelChange(event: VideoType) {
        this.type.set(event);
        this.videoTypeSelected.emit(event);
    }

}
