<kui-modal size='md'
    class="video-request-modal"
    [class.wide]="step() === Steps.CLIP_SETTINGS || step() === Steps.LIVESTREAM"
    [title]="'MEDIA.VIDEO_REQUEST.MODAL.NAME' | translate"
    [actions]="modalActions()">

    <div class="content">

        @switch(step()) {
            @case (Steps.ASSET_SELECTION) {
                <key-video-asset-selection (assetSelected)="handleAssetSelected($event)"></key-video-asset-selection>
            }
            @case (Steps.CAMERA_SELECTION) {
                <key-camera-selection 
                    [asset]="asset()" 
                    (cameraSelected)="handleCameraSelection($event.cameras)">
                </key-camera-selection>
            }
            @case (Steps.SUMMARY) {
                <key-video-request-summary 
                    [asset]="asset()" 
                    [clipSettings]="clipSettings()" 
                    [selectedCameras]="cameras()">
                </key-video-request-summary>   
            }
            @case (Steps.VIDEO_TYPE) {
                <key-video-type
                    [asset]="asset()" 
                    (videoTypeSelected)="handleVideoTypeChange($event)">
                ></key-video-type>
            }
            @case (Steps.LIVESTREAM) {
                <key-video-request-livestream [cameras]="liveCameras()"></key-video-request-livestream>
            }
        }

        <!-- I hate this, but its easier to handle next/back steps if we never remove the component from the DOM -->
        @if (asset()) {
            <key-clip-builder 
                [asset]="asset()"
                [reset]="step() === Steps.ASSET_SELECTION"
                [hidden]="step() !== Steps.CLIP_SETTINGS"
                (settingsChanged)="handleSettingsChange($event)">
            </key-clip-builder>
        }

    </div>

    <div footer>
        @if(step() === Steps.CLIP_SETTINGS) {
            <div class="info">
                <kui-icon name="circle-info" size="sm" color="info"></kui-icon>
                <p kui-action role="button" class="prompt" (click)="this.actions.custom(asset().id)">
                    {{'MEDIA.VIDEO_REQUEST.MODAL.CUSTOM_DATE_TIME_PROMPT' | translate}}
                </p>
            </div>
        }
    </div>

</kui-modal>