//                      _
//  _ __  _ __ ___   __| |
// | '_ \| '__/ _ \ / _` |
// | |_) | | | (_) | (_| |
// | .__/|_|  \___/ \__,_|
// |_|

export const environment = {
  /**
   * Application Version
   */
  version: 'fui-v2.74.0-prod',

  /**
   * The application state is stored in localstorage and rehydrated into the app when the page loads,.
   *
   * The prefix configured here provides a namespacing mechanism to ensure different environments and
   * apps do no conflict each other.
   */
  storePrefix: 'fui-v7-prod',

  /**
   * Production mode puts the app into production mode and enables production builds.
   * Production builds include minification, tree-shaking and other optimizations.
   */
  production: true,

  /**
   * Toggles “Hot Module Replacement” (HMR), a feature to inject updated modules into the active runtime.
   */
  hmr: false,

  /**
   * URL for sending errors to Sentry
   */
  sentryDsn: 'https://e14208fc88944756ac31c9dd98e36bc1@sentry.io/204409',
};
