import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, EventEmitter, Output } from '@angular/core';
import { BaseFormBuilderFieldComponent, FormBuilderField } from 'app/shared/components/form-builder';
import { AppService } from 'app/app.service';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY_GUID } from '../../admin/entities/utils';
import { escapeRqlValue } from 'app/shared/utils/rql';

interface TagItem {
    id: string;
    name: string;
    filter: any;
}

@Component({
    selector: 'key-form-builder-client-filter-field',
    templateUrl: './clientfilter.component.html',
})
export class KeyFormBuilderClientFilterFieldComponent implements BaseFormBuilderFieldComponent, OnInit, OnChanges {
    @Input() field: FormBuilderField;
    @Input() values: { [key: string]: any };
    @Input() error: string; // set this error value externally to have the default error highligh and display kick in

    @Output() onChange: EventEmitter<{ value: string, dirty: boolean }> = new EventEmitter();

    touched = false;
    dirty = false;
    isLoading = false;

    rootItems: TagItem[];
    ownerId: string;

    selectedItems: TagItem[] = [];
    autoCompleteItems: TagItem[] = [];

    constructor(
        public app: AppService,
        private i18n: TranslateService,
        private ref: ChangeDetectorRef
    ) {
    }

    validate(): boolean {
        this.touched = true;
        this.dirty = true;
        this.ref.markForCheck();
        return !this.field.required || this.selectedItems.length > 0;
    }

    ngOnInit() {
        setTimeout(() => { // avoid ExpressionChangedAfterItHasBeenCheckedError
            if (this.field.max === 1) {
                this.field.description = this.field.description || this.i18n.instant('FORMS.CLIENTFILTER.DESCRIPTION_SINGLE');
                this.rootItems = [];
            } else {
                this.field.description = this.field.description || this.i18n.instant('FORMS.CLIENTFILTER.DESCRIPTION_MULTIPLE');
                const anyClient = this.i18n.instant('FORMS.CLIENTFILTER.ANY_CLIENT');
                this.rootItems = [{
                    name: anyClient,
                    id: '00000000-0000-0000-0000-000000000000',
                    filter: {
                        name: anyClient,
                        actorType: 'client',
                        actorTypeId: EMPTY_GUID,
                        actorTypeName: this.i18n.instant('FORMS.CLIENTFILTER.CLIENT'),
                        actorSelectionType: 'any',
                        actorId: '00000000-0000-0000-0000-000000000000',
                        text: anyClient,
                    },
                }];
            }
            this.ref.markForCheck();
        });
    }

    ngOnChanges() {

        this.ownerId = (this.field.options && this.field.options.ownerId) || this.app.user.owner.id;

        if (this.field.max === 1) {
            const val = this.field.getValue ? this.field.getValue(this.field, this.values) : (this.values[this.field.id] || this.field.value);
            if (val) {
                if (val.id && val.name) {
                    this.selectedItems = [{
                        id: val.id,
                        name: val.name,
                        filter: null,
                    }];
                } else {
                    if (typeof val === 'string') {
                        this.isLoading = true;
                        this.app.api.accounts.getCompany(val).then(company => {
                            this.selectedItems = [{
                                id: company.id,
                                name: company.name,
                                filter: null,
                            }];
                            this.isLoading = false;
                            this.ref.markForCheck();
                        });
                    }
                }
            } else {
                this.selectedItems = [];
            }

        } else {
            this.values[this.field.id] = this.values[this.field.id] || [];
            this.selectedItems = this.values[this.field.id].filter(x => x).map(x => ({
                id: x.id || x.actorId,
                name: x.name || x.text,
                filter: x,
            }));
        }
    }

    updateValues() {
        this.dirty = true;
        this.touched = true;

        if (this.field.max === 1) {
            this.values[this.field.id] = this.selectedItems[0];
        } else {
            this.values[this.field.id] = this.selectedItems.map(x => x.filter);
        }

        this.ref.markForCheck();
        this.onChange.emit({ value: this.values[this.field.id], dirty: this.dirty });
    }

    addTag(value: TagItem) {
        if (this.field.max === 1) {
            this.selectedItems = [value];
        } else {
            this.selectedItems.push(value);
        }
        this.updateValues();
    }

    removeTag(value: TagItem) {
        const idx = this.selectedItems.findIndex(x => x.id === value.id);
        this.selectedItems.splice(idx, 1);
        this.updateValues();
    }

    updateAutoCompletionList(searchTerm: string) {
        const filter = searchTerm ? escapeRqlValue(searchTerm) : '';

        const clientText = this.i18n.instant('FORMS.CLIENTFILTER.CLIENT');

        this.touched = true;
        this.dirty = true;
        this.isLoading = true;
        this.ref.markForCheck();

        return this.app.api.accounts.listClients(this.ownerId, 0, 6, 'name:asc', 'state=active' + (!!searchTerm.length ? `&name=*${filter}*` : ''))
            .then(result => {
                return this.rootItems.concat(result.items.map(x => ({
                    id: x.id,
                    name: x.name,
                    filter: {
                        name: x.name,
                        actorType: 'client',
                        actorTypeId: EMPTY_GUID,
                        actorTypeName: clientText,
                        actorSelectionType: 'specific',
                        actorId: x.id,
                        text: x.name,
                    },
                })));
            })
            .catch(err => {
                console.error(err);
                return [];
            })
            .then((results: TagItem[]) => {
                this.autoCompleteItems = results
                    .filter(item => !this.selectedItems.find(x => x?.id === item.id));

                this.isLoading = false;

                this.ref.markForCheck();
            });
    }
}
