import { Component, Input, ChangeDetectionStrategy, ViewEncapsulation, Output, EventEmitter, OnChanges, Inject, HostBinding, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { format, setMinutes, setHours, getYear, parseISO } from 'date-fns';
import { MatchMediaService } from 'app/services';
import * as moment from 'moment-timezone';
import { Moment } from 'moment';

@Component({
    selector: 'kui-datetime',
    templateUrl: './datetime.component.html',
    styleUrls: ['./datetime.component.scss'],
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KuiDateTimeComponent implements OnChanges, OnDestroy {

    dateOffset = 0; // this is an offset we calculate that needs to be subtracted and added from p-calendar input & output
    dateInstance: Date;
    minInstance: Date;
    maxInstance: Date;
    isFocussed: boolean;

    @Input() type: 'datetime-local' | 'date' | 'time';
    @Input() format: string;
    @Input() inputStyle: 'normal' | 'none' = 'normal';
    /* expecting ISO string */
    @Input() date: string;
    @Input() max: string;
    @Input() min: string;
    @Input() disabled: boolean = undefined;
    @Input() icon: string;
    @HostBinding('class.disable-time') @Input() disableTime = false;

    @Output() onChange: EventEmitter<string> = new EventEmitter();

    id = Math.floor(Math.random() * 1e8);
    isNative = false;

    get yearRange(): string {
        const currentYear = new Date().getFullYear();
        return `${this.min ? getYear(this.min) : currentYear - 15}:${this.max ? getYear(this.max) : currentYear + 15}`;
    }

    constructor(@Inject(DOCUMENT) private document: HTMLDocument, public matchmedia: MatchMediaService) { }

    ngOnChanges(changes) {
        const { date, max, min } = changes;

        if (date) {
            if (this.date) {
                // the p-calendar component only works in the user's timezone. Unfortunately, we have users who want to view data in different timezones (stupid, i know). 
                // to fix this, we need to calculate the difference in timezone between our user and his browser, and then add it to whatever time was passed to us, feed
                // that to the p-calendar component and then reverse it at the end
                // if the user timezone is +1, and the browser is +2, then
                // a date of 2010-01-01T22:59:59.999Z in the user's timezone would be 2010/01/01 23:59:59 (correct), but in the browser's timezone would be 2010/01/02 00:59:59 (incorrect)
                // we need to trick the browser by subtracting the difference between user and browser from the date before giving it to p-calendar
                try {
                    const browserOffset = moment.utc().tz(moment.tz.guess()).utcOffset(); // minutes
                    const userOffset = moment.utc().tz(moment.defaultZone.name).utcOffset(); // minutes
                    this.dateOffset = browserOffset - userOffset;
                } catch (err) {
                    this.dateOffset = 0;
                }
                const dt = moment(this.date).subtract(this.dateOffset, 'minutes');
                this.dateInstance = dt.toDate();
            } else {
                this.dateInstance = new Date();
            }
        }
        if (max?.currentValue) {
            if(moment.isMoment(this.max)) {
                // someone gave us a moment value instead of a string value...
                this.maxInstance = parseISO((this.max as unknown as Moment).toISOString());
            } else if((this.max as any) instanceof Date) {
                this.maxInstance = this.max as unknown as Date;
            } else {
                this.maxInstance = parseISO(this.max);
            }

        }
        if (min?.currentValue) {
            if(moment.isMoment(this.min)) {
                // someone gave us a moment value instead of a string value...
                this.minInstance = parseISO((this.min as unknown as Moment).toISOString());
            } else if((this.min as any) instanceof Date) {
                this.minInstance = this.min as unknown as Date;
            } else {
                this.minInstance = parseISO(this.min);
            }
        }

    }

    ngOnDestroy() {
        this.setFocussedState(false);
    }

    formatNativeDate(date: string): string {
        if (date) {
            switch (this.type) {
                case 'time':
                    return format(date, 'HH:mm');
                case 'datetime-local':
                    return format(date, 'YYYY-MM-DDThh:mm:ss');
                case 'date':
                    return format(date, 'YYYY-MM-DD');
                default:
                    return date;
            }
        } else {
            return null;
        }
    }

    formatDate(date: string): string {

        const fmt = (dt: string, dateFormat: string): string => {
            return moment.utc(dt).tz(moment.defaultZone.name).format(dateFormat);
        };

        if (date) {
            if (this.type === 'time') {
                return fmt(date, this.format || 'HH:mm');
            }

            const getLocaleDateString = (): string => {
                const options: Intl.DateTimeFormatOptions = {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    timeZone: moment.defaultZone.name,
                };
                if (this.type === 'datetime-local') {
                    options['hour'] = 'numeric';
                    options['minute'] = 'numeric';
                    options['second'] = 'numeric';
                }
                try {
                    return new Date(date).toLocaleDateString(navigator.language, options);
                } catch (err) {
                    if (err.name === 'RangeError') { // Intl doesn't like the timezone name, just remove it
                        delete options.timeZone;
                        return new Date(date).toLocaleDateString(navigator.language, options);
                    }
                }
            };

            return this.format ? fmt(date, this.format) : getLocaleDateString();
        } else {
            return null;
        }
    }

    update(event: string | Date) {
        if (this.isNative && this.type === 'time') {
            let date = parseISO(this.date);
            const hh = (event as string).split(':')[0];
            const mm = (event as string).split(':')[1];

            date = setHours(date, +hh);
            date = setMinutes(date, +mm);

            this.onChange.emit(date.toISOString());
        } else {
            const dt = moment(event).add(this.dateOffset, 'minutes');
            this.onChange.emit(dt.toISOString());
        }
    }

    setFocussedState(state: boolean) {
        if (state) {
            this.document.body.classList.add('kui-datetime--open');
        } else {
            this.document.body.classList.remove('kui-datetime--open');
        }

        this.isFocussed = state;
    }
}
