import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnDestroy, OnInit, Output, WritableSignal, computed, input, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AppService } from 'app/app.service';
import { KuiModule } from 'app/key-ui';
import { AssetStateService, TripsService } from 'app/services';
import { TripItem } from 'app/services/trips/trips.service';
import { KeyDatesModule } from 'app/shared/components/dates/dates.module';
import { EventFeedService } from 'app/shared/components/feed/event-feed/event-feed.service';
import { KeyListingModule } from 'app/shared/components/listing/listing.module';
import { MAP_COLORS, MAP_COLORS_ENUM, getIconSvgString } from 'app/shared/components/map/map.markers';
import { SelectionItem } from 'app/shared/components/selection/selection.model';
import { PipeModule } from 'app/shared/pipes/pipe.module';
import * as moment from 'moment-timezone';
import {
    ReplaySubject,
    combineLatest,
    debounceTime,
    filter,
    from,
    mergeMap,
    switchMap,
    take,
    takeUntil,
    tap,
    toArray,
    zip
} from 'rxjs';

@Component({
    selector: 'key-trip-list',
    templateUrl: 'trip-list.component.html',
    styleUrls: ['trip-list.component.scss'],
    imports: [
        FormsModule,
        KuiModule, 
        CommonModule, 
        TranslateModule, 
        KeyDatesModule, 
        KeyListingModule, 
        PipeModule
    ],
    providers: [TripsService, EventFeedService],
    standalone: true,
})
export class TripListComponent implements OnInit, OnDestroy {
    asset = input.required<SelectionItem>();
    initialDate = input<string>();

    @Output() tripSelected = new EventEmitter<TripItem>();

    trips: WritableSignal<TripItem[]> = signal([]);
    selectedTrip: WritableSignal<TripItem> = signal(undefined);
    loading: WritableSignal<TripItem> = signal(undefined);
    loadingTrips: WritableSignal<Boolean> = signal(true);
    date: WritableSignal<string> = signal(moment.utc().toISOString());
    startDate = computed(() => moment(this.date()).utc().startOf('day').toISOString());
    endDate = computed(() => moment(this.date()).endOf('day').toISOString());
    private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

    constructor(
        private tripsService: TripsService,
        private app: AppService,
        private sanitizer: DomSanitizer,
        private eventsFeed: EventFeedService,
        private assetState: AssetStateService,
        private i18n: TranslateService,
    ) {
        combineLatest([
            toObservable(this.asset),
            toObservable(this.startDate),
            toObservable(this.endDate)
        ]).pipe(
            takeUntil(this.destroyed$),
            debounceTime(100), // hold off for a bit
            tap(_ => this.loadingTrips.set(true)),
            switchMap(([asset, start, end]) => 
                from(this.loadTrips(asset.id, start, end))
                    .pipe(
                        take(1),
                        mergeMap(trips => trips),
                        filter(trip => trip.distance > 0),
                        toArray(),
                    )
            ),
        ).subscribe(trips => {
            this.loadingTrips.set(false);
            this.trips.set(trips);
        })
    }

    ngOnInit() {
        this.date.set(this.initialDate());
    }

    ngOnDestroy() {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }

    selectTrip(trip: TripItem) {
        this.loading.set(trip);
        zip([
            from(this.tripsService.loadTripTelemetry(trip)),
            from(this.eventsFeed.getHistory(this.app.client.id, 'asset', trip.assetId, trip.dateStart, trip.dateEnd, 10))
        ]).pipe(
            take(1)
        ).subscribe(res => {
            trip.telemetry = res[0];
            trip.events = res[1].items;
            this.tripSelected.emit(trip);
            this.selectedTrip.set(trip);
            this.loading.set(null);
        });
    }

    getTripIcon(trip: TripItem): SafeHtml {
        let icon = '';

        if (trip.tripType === 'active') {
            icon = getIconSvgString(this.app.theme, 'direction', {
                size: 20,
                rotation: 45,
                fill: this.asset().color ?? 'blue',
            });
        } else {
            icon = getIconSvgString(this.app.theme, 'idle', {
                size: 20,
                rotation: 45,
                fill: MAP_COLORS[MAP_COLORS_ENUM['lightgray']],
            });
        }

        return this.sanitizer.bypassSecurityTrustHtml(icon);
    }

    getListTitle(start: string, end: string): string {
        const trim = (text: string): string => text ? text.split(',')[0] : this.i18n.instant('SHARED.UNKNOWN_LOCATION');
        return this.i18n.instant('HISTORY.START_TO_END', { start: trim(start), end: trim(end) });
    }

    handleDateChange(isoDate: string) {
        if (isoDate) {
            this.date.set(isoDate);
        } else {
            this.date.set(moment.utc().toISOString());
        }
    }

    private async loadTrips(id: string, start: string, end: string): Promise<TripItem[]> {
        const trips = await this.tripsService.loadTrips(id, start, end)
        // Create an inprogress trip from the asset state.
        // This will only work if the user has already been to the overview page, but since this is most
        // likley the case, this should work most of the time. Revisit if that becomes a problem.
        try {
            const asset = await this.assetState.getAsset(id);
            if (asset && asset.telemetry && asset.telemetry.trip) {
                if (moment(asset.telemetry.date).isBetween(start, end)) {
                    const trip = asset.telemetry.trip;
                    trips.push({
                        id: 'in-progress',
                        assetId: asset.id,
                        tripType: asset.telemetry.active ? 'active' : 'inactive',
                        linkedAssets: asset.telemetry.linked.filter(x => x.id !== asset.id),
                        dateStart: trip.start,
                        dateEnd: asset.telemetry.date,
                        addressStart: trip.startAddress,
                        addressEnd: asset.telemetry.location && asset.telemetry.location.address,
                        latStart: trip.startLat,
                        lonStart: trip.startLon,
                        latEnd: trip.lastLat,
                        lonEnd: trip.lastLon,
                        distance: trip.distance,
                        duration: moment(asset.telemetry.date).diff(trip.start, 'seconds'),
                        driveTime: 0,
                        idleTime: 0,
                        inprogress: true,
                    });
                }
            }
        } catch (err) {
            // any errors here should not prevent historic trips from loading
            console.error('Unable to load in progress trip', err);
        }
        return trips;
    }
}
